import "../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VU227bMAx9z1cIBQa0DwoUx8lSBf2SYRgUi3bUypIh0XGyof8+SL4mMNJ2wF4si6TIc45ILX9tNil7PTHyZ0FIZb1CZQ0n4uCtrhH2C0Kk8pUWF05yDedgCCuVykHWBmdW16UJntfao8ovNLMGwSAnGRgEF1xCq8JQhVD6qfkIqjgiJyvGvoV9oyQexy06YQZMWhO2XHsCwgNVhtoa94v3xbLjsIoc/gPamMGjcPgJxIWoONlVsXQlpFSm4GS1bQ2lONMuPmWstU0pDv77RH9IgYL6o20CrOzt5QFdDQ8/owCTGtu2xngyiRE3iO0JXK5tw8lRSQlRGoQz0sYFLuEbeVon6cGBeOMkLjRYptnXMXuvj6jR9px7G5sj3DpnGJNwV9ZxchLukVKR58oAPSU0ovOQWSOFuzzFW7YGqVe/gZNV2gqrQ3RfOUlaY4xrOmPK2BX+D3Udst0Km95Kf9Md6/6228byoPOx3cY0m5jm37ncV6xyquz1KoUrlKEHi2hLTtJrOtv5UeoOoa3Glp5r/3UH8KvPwQjge/se9fOTVud+pqbidGVKZQbZk5uL2c0z+aD8czw1bdT4n1tXzvZpJ4yGHIfh7xF1Og3PxvYa4PPdphvKcuIsCoTH1Y5JKJ72i/e/KPV1frwFAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = '_5540jv7';
export var actionContainer = '_5540jv6';
export var actionContent = '_5540jv8';
export var arrowIcon = '_5540jv9';
export var errorContainer = '_5540jv1';
export var errorLayout = '_5540jv0';
export var illustration = '_5540jv4';
export var label = '_5540jv2';
export var scrollArea = '_5540jv3';
export var text = '_5540jv5';