import "../../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW23LqIBR971fw0hk7I57czIW+9FcwIbpbBAeI1p7pv5+BJEqiJ22d+iISYK299trs5OWNHWtFt0yjV51qKCP09wGh4NENCBm5IwgnyeOzm3JWG4JwGHZzo6jQtVRbgrbUKHif4WARFXOEg0WRzlGwKBI3iZdzFMxR8GTPfT4gFKY9RY+Z95iOcjlJkcUONS0sRdpSZNmIIo5GFNmAIQmmGIrMgUaxZQjTVlExYkjysYjlgCIupijy3GIneSvF/eYjgjS5t4Q8uLsPYXDvcvp8eBnXcTys444pDnbvA4PO82vUnTetUSefpus4zkYU2SRF3vqyjCxF3EmNvqrkaKQjnNIROtAgckMYfbOUo5GOaFrGDbX8+yIuivkeZnjlfKeq+nxYtEWcOJ4DVGZDHK9d3zBYb8x5vpMaDEhBkGKcGtgz+1Tumaq5PBC0gapi4vmMuvRR83gAmocjTLrSkjfGYZ7kuj291nZyg1C5oyWYI7GLdl4DN0wRtOKNmu2pmmHcBhw+PQ04sFSwBkFQyYRhqhvsFipgS9vA+zYQa8SoZhgElo1BtVQHqiqNQNQgoBV2OoZ3nB6xNtQwgna00azyEpe6xF3frBohQKy93Zmf5iIfpLnIJtI8aJLDFnlbg7xDog/AOS43VKwZcTHObdjz89GrbkS/6Ub+IzeK4VVKhnYkE3aods/Vsr85swP/ct+/9o0a+y/RL/04qaRO5fWOUIG2KSIIBAfBcM2Z61J2xHqjQLwRFHhgKwfWKwgIaoRm5vmcRl9gG2mf0MuVWgqDNXwwgkrKy9lwB/qDoqdBkH10lMNaYDBsq4lXf6+NNlAfcSmFYcL4S3umDJSUY3eUoC1UFXcZaDRTWDPOSkOQkILd1D7L7zRluXplpcE12NAsmAdQfQvgIoBfSc6Klm9rJRtR4VJyqXqraF3bmtgp2FJ1bNecIdd2HTZgmFt1hXQK/eTzoXuyDPx6Yj97m/l38EL5hMT/JOVSuVqv6CwN5igN5yh1X8lLp+oDg6jYe6foap4a5afh3AZO17UT0a2gRbjUDqxR2qLtJIyuLiMb67nL0Qkv9DbUo7t9rUfl7YfH4IttDxpWwB3euZZ8jef20cUwYPTPu//2Mn3+AxStj/s5DgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = 'js6sic7';
export var DefaultAvatarBottomItemWithAnimationStyle = 'js6sic8';
export var DefaultAvatarContainerStyle = 'js6sic4';
export var DefaultAvatarMiddleItemStyle = 'js6sic5';
export var DefaultAvatarMiddleItemWithAnimationStyle = 'js6sic6';
export var DefaultAvatarTopItemStyle = 'js6sic9';
export var avatarFallback = 'js6sicd';
export var avatarImage = 'js6sicc';
export var avatarRoot = 'js6sica';
export var avatarWrapper = 'js6sicb';
export var blurVar = 'var(--js6sic1)';
export var hoverWrapper = 'js6sice';
export var removeButton = 'js6sicf';
export var sizeVar = 'var(--js6sic0)';