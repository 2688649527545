import "../../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVUY/aMAx+51fk5aRDuqBSuI0L2i+Zpik0LvUuJF3iHrDT/vuUtIU2cJzQXlBxYvvzZ3/O7OdcuuX2dzln7xPGauuR0BrBHGhJ+AbrCWN7VFQJNs+yh/C3AtxWdP6/Q8N72yKvD+vJ38msj5vHuAp9reVRsFLDIfhIjVvDkWDnBSvAELiR2yK62TdwpbZ7fhCsQqXArD8EmaLaylqwVYJmGcMGEHzvwoXwu+5NCh0UbeTC6mZnLpDGa56ko1OKZZLi+XrBbUB+RvUBB8Py5MZb3dDV8nbywJO+kJPm5Ko1y2a5Z9ls4RlIDxwNtw2NsM5FFShmCfhB7DfpHjnvj7PpyP1LMjNDvGRrwbLwpaGk7tO1NWTXCrK1LJCO3em1WoJ9I4vXrbONUYJpNCAd3zqpEAw9vmQKtk+tay0dGGLZw1NXgixLNMBjvbyw2jpeotagpmyZPUxv09IWekI4H93+Gg/7cvK8be7FAASVdKRmN7r/q/GE5ZEX1hAYEszXsgC+AdpDGP9hYqGlJ15UqFXbt3OK0MIuxMhndYccPwfDWC2VQrMVLOunOnIrxqwTHIjXDnfSHVvyp7GZ1qm+G4nH8GjcnNV3JUny0haNB/WNXAM/YlE3oqWpB+Fe2o1gDXGPf0AwNBU4pAG+MF+ND5NaX0h52Zq6m70gR0ZPRw2CeatRpSM8rvl0cIWrod7zjupUQpHmfmcKBlpj7THqZl8hAY/tE8zYdu0NaJApDSNkrX03HXV8WR/YPL+kpAN3L5hzOYssWambz8FV/HmabpjzYvKExetxJEs0OjpHAQxyFTFXx3TC82rU134snlvy26ehcmheY/5BTDV6zIZP2f0kNT6MFGgoKJgNjDLB/8n7fHQatWTS8nsoYKxonA9qrC1ePPHQbdn3W5oY7Oug238Vmq70rwgAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--_1ar4gqf0)';
export var innerBackdrop = '_1ar4gqf6';
export var innerContainer = '_1ar4gqf5';
export var root = '_1ar4gqf1';
export var showMoreTag = '_1ar4gqfb';
export var tag = '_1ar4gqf7';
export var tagIndicator = '_1ar4gqfc';
export var tagInline = '_1ar4gqf9 _1ar4gqf8';
export var tagInnerWrapper = '_1ar4gqf8';
export var tagLabel = '_1ar4gqfd';
export var tagListItem = '_1ar4gqfa _1ar4gqf7';
export var tagRemove = '_1ar4gqfe';
export var tagsContainer = '_1ar4gqf2';
export var tagsListContainer = '_1ar4gqf4 _1ar4gqf2';
export var tagsScrollContainer = '_1ar4gqf3 _1ar4gqf2';