import "../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWy46jMBC85yt8WWnn4AiYJJtxPmblQAM9Y2xkm8Dsav99ZUPASchrpLlEirGru6ury17+jj+quGt1RP4uCCkBi9IyEkdR3e0WhHCBhaRooTKMpCAtaLdc8yxDWTCy2tYdiTd1R5LI/az6Y+oAOheqZaTELAPp1jI0teCfjOQC/Kb3xljMP2mqpAVpGTE1T4HuwbbQH9nz9KPQqpEZIweuf1LK8xwl0OkDrTVWXDsUofTLbvFvsTzWFPuaciUtNfgHzjD8eknXL7vjpnaofh1Fbs0jnh2y0FlqIFUyC4POVHeFuYLXjGx7lo5sJ8P/xoCmBgSklhGpJJxUk/hq5pLCVMm5VFAKX+itjIIAr1cD+KpvEL3yJx+l4H7fR3nFdXck6+G87nS8M37PXukMNNU8w8Y4wY9RmkpS36VBy/3OPhejBGZnUuyBpuhfES0hFe9oi5ktGXmNDq1b8tVNkwRCYG3QuE9tiRao580ppdW8PhFU0qfu2z/O9HLzCypfZKON47JWeCGCtW/lkMmZTrchISN16+iHJ11AR02pUX4wEp1gbjzmnCc8W2IA+uvRcQjUEHteApDtF0CS6AzkbV79npAMNaQWlWSDtHYTu3HUMzeqfXsGzD3w+DkKziZvm2tOGwDs5zMLPchn6cgNdTSGdMYeD2KqUI5aWiU3rocggbR3YBej0C7J+HS83Z0R9fLXBUpG6LR0nLveCAlRjXV6nhZujrmppr7lvELh/bAEjTZwk2AlmM7ho9VcmpprkPYu0yljteAplEpkoK+qKtgz56PZs0pyrjH25HhnT4nyxqqTAHAyibQLZ7FWBnt8DYJbPMB5hOQYYRJQgJ0/lrxW7UMPi+FdEfrPa3Ie/jERXPjdhYetnrxjwrJZ6fj0xV9q6ATKb5zHOKBBC9mXb+B8maHhewE3s2mkARsyciGQ4hvsv/wO+8cb1hbfcqeZ98eMcb3ffzoOD4mnXo4XHfwPqsacH4ALAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowDownSmallIcon = '_1km1xwr7';
export var buttonText = '_1km1xwrj';
export var docListHeader = '_1km1xwr0';
export var docListHeaderTitle = '_1km1xwr1';
export var rightButtonGroup = '_1km1xwri';
export var searchIcon = '_1km1xwr8';
export var searchInput = '_1km1xwrc';
export var tagIcon = '_1km1xwrg';
export var tagIndicator = '_1km1xwr5';
export var tagLabel = '_1km1xwr6';
export var tagSelectorItem = '_1km1xwrf';
export var tagSelectorItemText = '_1km1xwrh';
export var tagSelectorTagsScrollContainer = '_1km1xwre';
export var tagSticky = '_1km1xwr4';
export var tagsEditorRoot = '_1km1xwr9';
export var tagsEditorSelectedTags = '_1km1xwrb';
export var tagsEditorTagsSelector = '_1km1xwrd';
export var tagsMenu = '_1km1xwra';
export var titleCollectionName = '_1km1xwr3';
export var titleIcon = '_1km1xwr2';