import "../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UzY7aMBC+8xSjSpXgYJSw7HbrvfRVJvEkuDi2ZQ+QbcW7V3FgE+9Cq/bUE2H+/P3Ys9aqxPhUwM8FwI50u2MJZVF8flkAnLTi3fRX6egNvkpoDPVDYPgVSgeqWTsroXbm0NmUcZZF1D9IwhHDUghsGm1JpHiFkVZDlXdRj52BDLI+0hCtsN63wR2setc8JYQPusPwKmpnXFi9LM6L9UikTERSWIK2OwqaZ+lNSrPzEorhLEMNXz7DyL24yXRC2uie1BBCo1srNFMXJdRkmcIQ/n6IrJsBmWWyLCF6rElUxCeipI1HpbRtJZRPvodyU/h+hvAhc+Jx4/sbTtyAI05U7TUL9F4EalNSBWxno7dp9Aduo2uiRS9hm2F5nIuZWcHU8wcT/mB77Kaa05VfkfRO4xTVLuBIyzpLmVbbQarnDN1TQjc7crP1F7P04IagI1mOEvDAbt639ga1/Ttub91fU9/kx/H0kr+c4y4DXvzrlQaoXFAUREClD/E6KcUmgSrXi7hD5U7X2BtS/H/cKze+h/GiA3TYi7v3OVsEN/hzQHutncSDYv0QgTBm/CU2TOGiwuU1fvqUn4dVdObAl/OYXfd+BVTG1fvZG6zR1MsBOAjYFr5fzf0v1o8jyfuOG6z3oixWGVLreCkNRhauEfzqaZVgdxhabcUM2HnxrSOlEWIdiCygVbDMFB1ewdid7bz3e+fqxnkq3F4K36hffZzVPN+vuQ1N299A+3Jv2j2LzovzL54xWGOyBgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = 'id1as61';
export var draggableHeader = 'id1as63';
export var hideInSmallScreen = 'id1as68';
export var hideInWideScreen = 'id1as67';
export var iconButton = 'id1as66';
export var menu = 'id1as69';
export var menuItem = 'id1as6a';
export var root = 'id1as60';
export var topNav = 'id1as62';
export var topNavLink = 'id1as65';
export var topNavLinks = 'id1as64';