import "../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62T0U6DQBBF3/sVG5/swzSAbW238UuMMQsMdBSWzTItVOO/m11shUqjTXwiWWbPvTNzd/YcrmOVbCkQ7xMhStLQUMpbKaIoMO1m8jGZHUvCrkTZnDQUmLEU4dLVCAENxq/EoIwBizlVWgpdQWpVPkBEHmFUmpLOwVK+ZSlWZzp3viirNENNbyjFXtlbAJVlpBH8eVtPnWxWYAuNVcapue+Q85gqVqASpj0+3LDd4c2TZydVUdkzrrFUKnsA/286AM39pZRqU6iD9KpOXRWUayDGspYiQc1o3fHLrmbKHEgzapaiNipBiJEbRO0qcmd41U3Ot9NgN4lFEGz+1HrP3OJ7WkfMvMOMdcnYMtSYVDq90OyyvyIp5qYVYdR53X7xo77331Y00t8Vxk55iyvmqvR+Bnbv/9Nuj7sa33kvcV3enMH29GaCoJPqb3jEW09nfTGQfiIp1SoucGxR66vi7Wk/Mv4JtPf7bf8DAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_19bachi5';
export var headerDisplayButton = '_19bachi1';
export var listOption = '_19bachi6';
export var menu = '_19bachi0';
export var properties = '_19bachi7';
export var propertiesWrapper = '_19bachi8';
export var propertyButton = '_19bachi9';
export var subMenuItem = '_19bachi3';
export var subMenuTrigger = '_19bachi2';
export var subMenuTriggerContent = '_19bachi4';