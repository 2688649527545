import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61X3Y6bPBC9z1NYrSrtXhgBSXa3Xqnqm1QDHhJ/MTayTZJtte9eYUwCLCTsp14lmBn7nDN/5ucB3woDJVryK7HPiUv1mvxZERJ/8z+EOAPKFtqUjBjtwOFDzHH3+Loi5H1FyPaGYfIyME3iG7brp57t+yrq0MQkknqnvV+hlaNW/EZGjmAeKIWiEAqpX9/Ttff2DycUu71j5CmOm7VcS21GTpmE/OA9SjA7oagJLtW5WTyicSIHSUGKnWKkFJxLfB1C+0EqVghjHdUFdW8VLoG5nYUZgGTaOV0ysmmRyMZ3HyzTl3aRC1tJeGOkkOgXPEwqHJaWkRyVQzMBVsLnsG5msU6g6p2W+M0r4Fyo3YXQOm7BV9oKJ7RixKAEJ45DXZPoJNxe147uhXKTO6XxhwMlZCi98UWbTOr8MBd/h2dHLeZacTBv1Js8zkfhplC2XKhTOoKd3g9C2PuqGWRWy9phszpOFSwcI0vOjdAYbfzpU+L4txdNBo4nMEqo3axreD/lvPFOJ8HdnpFk22LuMHbPU8nREDUcDTXARW1Z03WaVX1EU0h9YmQvOEflLSE/7IyuFaeTZd9uNIWOMSgcdqIoh8ox8uXLaw9zOsKcjjH3g9OLRugt/r/TVfjXRa9X/IxA7fQ0j69FUTRvflOhOJ4ZSeeUGbLKsNAGb9BK4yGt7vmelLLGG7nZ0g+R6qiGxwuFxCvSTQOqjfAiNL7Bxzc3JUpoT7hOqSTaWiJUIZRw6PMdhi1ve7+4MrAthUG5zA+X0Bt8CNfjHrQlMFsVUqjDVNI9Tba3zbJG+Txsd90o+K+2ThRNSwvBthXkSDN0J2xr5N6weFnYGJa1Lt9pwxjtDhspnkTbT9fRJaVarYb10yPz3ZPpTlqnMwP0g2pXqJNy3UmG7yQyaNGXDsfrJJuaEUtEHF5SxvMWBhyTOD7u+20rjo+nf0R6STkNgEUnA1UV2uqnry1AooDN+5dwpoHUczyWHEjkhJMLLjbebOk17MPlBkhk6+x61H37z/eF7F5Av03Gs/mlXBjM2zLKtaxL9X8i3cOS98f2usv0nyVyAcTmBlERUJw89KKTxOmmOj96z1GW3kJ5aYWeY3W+jKIJAn4X68C47gNjLutunTjw48H8oqvSCrtPktuEtw3SEd88bDeK2vvq/S+U7Wf8dw0AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_1s71t2o6';
export var authCodeErrorMessage = '_1s71t2o8';
export var authCodeWrapper = '_1s71t2o7';
export var authContent = '_1s71t2o5';
export var authInputWrapper = '_1s71t2o1';
export var authPageContainer = '_1s71t2oa';
export var formHint = '_1s71t2o2';
export var hideInSmallScreen = '_1s71t2od';
export var input = '_1s71t2oc';
export var loading = '_1s71t2o4';
export var modalHeaderWrapper = '_1s71t2o0';
export var resendButtonWrapper = '_1s71t2o9';
export var signInPageContainer = '_1s71t2ob';